import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    loggedIn: false,
    id: '',
    userInfo: null,
  },
  getters: {
    loggedIn(state) {
      return state.loggedIn;
    },
    userInfo(state) {
      return state.userInfo;
    },
  },
  mutations: {
    loggedIn(state, value) {
      state.loggedIn = value;
    },
    userInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
  },
  actions: {
    'socket.login': ({ commit, dispatch }, data) => {
      if (data.result === 'success') {
        const accessToken = Vue.$ls.get('access');

        if (accessToken == null) {
          // 초기화 완료
          commit('core/initialized', true, { root: true });
          dispatch('router/push', '/welcome', { root: true });
        } else {
          // Access 키 인증 시작
          Vue.$socket.emit('term.user.login', { accessToken });

          // 예전 경로 기억
          // commit('router/lastPath', )
        }
      } else {
        // 실패 메시지
        dispatch('alert/setAlert', {
          message: data.message,
          color: 'error',
        }, { root: true });
      }
    },
    'socket.term.user.cert.req': ({ state, dispatch }, data) => {
      if (data.result === 'success') {
        Vue.$socket.emit('term.user.cert.verify', {
          id: state.id,
          certNumber: data.certNumber,
        });
      } else if (data.name === 'NOT_INITIALIZED') {
        dispatch('router/push', `/welcome/reset/${state.id}`, { root: true });
      } else {
        // 실패 메시지
        dispatch('alert/setAlert', {
          message: data.message,
          color: 'error',
        }, { root: true });
      }
    },
    'socket.term.user.cert.verify': ({ dispatch }, data) => {
      if (data.result === 'success') {
        Vue.$ls.set('access', data.accessToken);

        // Access 키 인증 시작
        Vue.$socket.emit('term.user.login', { accessToken: data.accessToken });
      } else {
        // 실패 메시지
        dispatch('alert/setAlert', {
          message: data.message,
          color: 'error',
        }, { root: true });
      }
    },
    'socket.term.user.login': ({ commit, rootGetters, dispatch }, data) => {
      commit('core/initialized', true, { root: true });

      if (data.result === 'success') {
        commit('loggedIn', true);
        commit('userInfo', data.userInfo);
        dispatch('config/initialize', data.userInfo.id, { root: true });

        if (data.userInfo.control === 'Y') {
          dispatch('devices/getDevices', null, { root: true });
          dispatch('devices/startGetDevicesTimer', null, { root: true });

          dispatch(
            'stat/getDeviceStat',
            {
              items: [
                'registered',
                'time',
                'distance',
                'events',
                'alarm',
                'data',
              ],
            },
            { root: true },
          );
        }

        dispatch('router/push', rootGetters['router/lastPath'], { root: true });
      } else {
        // 인증 취소 확인 및 초기화 완료
        dispatch('router/push', '/welcome', { root: true });
      }
    },
    'socket.term.user.password.reset': ({ dispatch }, data) => {
      if (data.result === 'success') {
        dispatch('router/push', '/welcome', { root: true });

        // 성공 메시지
        dispatch('alert/setAlert', {
          message: '비밀번호 재설정 완료했습니다.',
          color: 'primary',
        }, { root: true });
      } else {
        // 실패 메시지
        dispatch('alert/setAlert', {
          message: data.message,
          color: 'error',
        }, { root: true });
      }
    },
    initialize({ dispatch }) {
      // term Login
      Vue.$socket.emit('login', { type: 'term' });
      dispatch('router/push', '/loading', { root: true });
    },
    login({ state }, form) {
      state.id = form.id;

      Vue.$socket.emit('term.user.cert.req', {
        ...form,
        type: 'pw',
      });
    },
    logout() {
      Vue.$ls.remove('access');
      window.location.href = '/';
    },
    resetPassword(_, form) {
      Vue.$socket.emit('term.user.password.reset', form);
    },
  },
};
