import Vue from 'vue';

function getAcc(items) {
  return items.reduce((acc, item) => ([
    acc[0] + item.item[0],
    acc[1] + item.item[1],
    acc[2] + item.item[2],
    acc[3] + item.item[3],
  ]), [0, 0, 0, 0]);
}

export default {
  namespaced: true,
  state: {
    each: {
      time: [], // 총 운행 시간
      distance: [], // 총 운행 거리
      events: [], // 총 이벤트 수 (only impact)
      alarm: [], // 총 알림 수
      data: [], // 총 데이터 사용량
    },
    total: {
      registered: [], // 등록된 장치 수
      time: [], // 총 운행 시간
      distance: [], // 총 운행 거리
      events: [], // 총 이벤트 수 (only impact)
      alarm: [], // 총 알림 수
      data: [], // 총 데이터 사용량
    },
  },
  getters: {
    registered(state) {
      return {
        total: state.total.registered,
      };
    },
    time(state) {
      return {
        each: state.each.time,
        total: state.total.time,
      };
    },
    distance(state) {
      return {
        each: state.each.distance,
        total: state.total.distance,
      };
    },
    events(state) {
      return {
        each: state.each.events,
        total: state.total.events,
      };
    },
    alarm(state) {
      return {
        each: state.each.alarm,
        total: state.total.alarm,
      };
    },
    data(state) {
      return {
        each: state.each.data,
        total: state.total.data,
      };
    },
  },
  mutations: {
    registered(state, items) {
      state.total.registered = items;
    },
    time(state, items) {
      state.each.time = items;
      state.total.time = getAcc(items);
    },
    distance(state, items) {
      state.each.distance = items;
      state.total.distance = getAcc(items);
    },
    events(state, items) {
      state.each.events = items;
      state.total.events = getAcc(items);
    },
    alarm(state, items) {
      state.each.alarm = items;
      state.total.alarm = getAcc(items);
    },
    data(state, items) {
      state.each.data = items;
      state.total.data = getAcc(items);
    },
  },
  actions: {
    'socket.term.stat.get': function _({ commit, dispatch }, data) {
      if (data.result === 'success') {
        Object.entries(data.stats).forEach((item) => {
          commit(item[0], item[1]);
        });
      } else {
        dispatch('alert/setAlert', {
          message: data.message,
          color: 'error',
        }, { root: true });
      }
    },
    getDeviceStat(_, data) {
      data.items.forEach((item) => {
        Vue.$socket.emit('term.stat.get', {
          items: [item],
        });
      });
    },
  },
};
