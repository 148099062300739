import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    downloads: [],
    show: false,
  },
  getters: {
    downloads(state) {
      return state.downloads;
    },
    show(state) {
      return state.show;
    },
  },
  mutations: {
    addDownloads(state, item) {
      state.downloads.push({
        signature: null,
        downloadId: null,
        serial: item.serial,
        fileName: item.fileName,
        done: false,
        current: 0,
        total: 0,
      });

      state.show = true;
    },
    attachDownloads(state, item) {
      const emptyIdx = state.downloads.findIndex((download) => (download.downloadId == null));
      if (emptyIdx >= 0) {
        state.downloads.splice(emptyIdx, 1, {
          ...state.downloads[emptyIdx],
          signature: item.signature,
          downloadId: item.downloadId,
        });
      }
    },
    updateDownloads(state, item) {
      const foundIdx = state.downloads.findIndex(
        (download) => (download.downloadId === item.downloadId),
      );

      if (foundIdx >= 0) {
        state.downloads.splice(foundIdx, 1, {
          ...state.downloads[foundIdx],
          current: item.current,
          total: item.total,
        });
      }
    },
    doneDownloads(state, item) {
      const foundIdx = state.downloads.findIndex(
        (download) => (download.downloadId === item.downloadId),
      );

      if (foundIdx >= 0) {
        state.downloads.splice(foundIdx, 1, {
          ...state.downloads[foundIdx],
          downloadPath: item.downloadPath,
          done: true,
        });
      }

      state.show = true;
    },
  },
  actions: {
    'socket.download.start': function _({ commit }, data) {
      if (data.result === 'success') {
        commit('attachDownloads', {
          signature: data.signature,
          downloadId: data.downloadId,
        });
      }
    },
    'socket.download.stop': function _({ commit }, data) {
      if (data.result === 'success') {
        commit('doneDownloads', data);
      }
    },
    'socket.term.download.state': function _({ commit }, data) {
      if (data.result === 'success') {
        commit('updateDownloads', data);
      }
    },
    startDownload({ commit }, data) {
      Vue.$socket.emit('download.start', {
        serial: data.serial,
        fileName: data.fileName,
        protocol: 2,
        mode: 1,
        type: data.type,
      });

      commit('addDownloads', data);
    },
    showState({ state }, show) {
      state.show = show;
    },
  },
};
