import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { needAuthorization: true, needControl: true },
  },
  {
    path: '/devicelist',
    name: 'DeviceList',
    meta: { needAuthorization: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "main" */ '../views/DeviceList.vue'),
  },
  {
    path: '/live',
    name: 'Live',
    meta: { needAuthorization: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "main" */ '../views/Live.vue'),
  },
  {
    path: '/location',
    name: 'Location',
    meta: { needAuthorization: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "main" */ '../views/Location.vue'),
  },
  {
    path: '/drivinghistory',
    name: 'drivinghistory',
    meta: { needAuthorization: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "main" */ '../views/DrivingHistory.vue'),
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: { needAuthorization: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "main" */ '../views/Settings.vue'),
  },
  {
    path: '/ctrluserlist',
    name: 'CtrlUserList',
    meta: { needAuthorization: true, needManage: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "manage" */ '../views/CtrlUserList.vue'),
  },
  {
    path: '/devicelistall',
    name: 'DeviceListAll',
    meta: { needAuthorization: true, needManage: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "manage" */ '../views/DeviceListAll.vue'),
  },
  {
    path: '/firmware',
    name: 'Firmware',
    meta: { needAuthorization: true, needManage: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "manage" */ '../views/Firmware.vue'),
  },
  {
    path: '/welcome',
    meta: { needAuthorization: false },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "main" */ '../views/Welcome.vue'),
    children: [
      {
        path: '',
        name: 'Login',
        meta: { needAuthorization: false },
        component: () => import(/* webpackChunkName: "welcome" */ '../views/welcome/Login.vue'),
      },
      {
        path: 'reset/:id',
        props: true,
        name: 'Reset',
        meta: { needAuthorization: false },
        component: () => import(/* webpackChunkName: "welcome" */ '../views/welcome/Reset.vue'),
      },
    ],
  },
  {
    path: '/loading',
    meta: { needAuthorization: false },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const initialized = store.getters['core/initialized'];
  const loggedIn = store.getters['user/loggedIn'];
  const userInfo = store.getters['user/userInfo'];
  const manage = userInfo && (userInfo.manage === 'Y');
  const control = userInfo && (userInfo.control === 'Y');

  const lastPath = window.location.hash.slice(1);
  store.commit('router/lastPath', lastPath);

  if (initialized) {
    if (loggedIn && to.meta.needAuthorization) {
      const needManage = (to.meta.needManage != null) ? (to.meta.needManage) : false;
      const needControl = (to.meta.needControl != null) ? (to.meta.needControl) : false;

      if (needManage && !manage) {
        next('/');
      } if (needControl && !control) {
        next('/devicelistall');
      } else {
        next();
      }
    } else if (!loggedIn && !to.meta.needAuthorization) {
      next();
    } else if (loggedIn && !to.meta.needAuthorization) {
      next(false);
    } else if (to.path === '/loading') {
      next('/');
    } else {
      next(false);
    }
  } else if (to.path === '/loading') {
    next();
  } else {
    next(false);
  }
});

export default router;
